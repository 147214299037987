<template>
  <div>
    <div class="mail-create-create background__blue_document">
      <p class="title-secondary color__blue_main fs-16 fw__bold">{{ title || $t('mails.add_mail') }}</p>
    </div>
    <div class="page-main-content mt-0" @keyup.enter="submit()">
      <div class="columns">
        <div class="column">
          <div class="d-flex align-items-center m__bottom--6">
          <span class="input-title fs-12 color__blue_main fw__bold">{{$t('mails.input_field.name.label')}}<i
              :data-tooltip="$t('tooltip.required')"
              class="required-note">*</i></span>
          </div>
          <div class="">
            <label>
              <input
                  :class="{'is-error': (!vuelidate.mailTemplate.name.required && vuelidate.$dirty) || (error_server.name && error_server.name.length)}"
                  class="input"
                  :placeholder="$t('mails.input_field.name.placeholder')"
                  type="text"
                  v-model.trim="mailTemplate.name">
            </label>
            <span class="error pt-1" v-if="error_server.name && error_server.name.length">
                          {{ error_server.name[0] }}
          </span>
            <template v-else-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.mailTemplate.name.required">
                        {{ $t('mails.messages.validation.name_required') }}
            </span>
              <span class="error pt-1" v-if="!vuelidate.mailTemplate.name.maxLength">
                        {{ $t('mails.messages.validation.name_maxlength') }}
            </span>
            </template>
          </div>
        </div>
        <div class="column">
          <div class="d-flex align-items-center m__bottom--6">
          <span class="input-title fs-12 color__blue_main fw__bold">{{$t('mails.input_field.title.label')}}
            <i :data-tooltip="$t('tooltip.required')"
               class="required-note">*</i>
          </span>
          </div>
          <div class="">
            <label>
              <input
                  :class="{'is-error': (!vuelidate.mailTemplate.subject.required && vuelidate.$dirty) || (error_server.subject && error_server.subject.length)}"
                  class="input"
                  :placeholder="$t('mails.input_field.title.placeholder')"
                  type="text"
                  v-model.trim="mailTemplate.subject">
            </label>
            <span class="error pt-1" v-if="error_server.subject && error_server.subject.length">
            {{ error_server.subject[0] }}
          </span>
            <template v-else-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.mailTemplate.subject.required && vuelidate.$dirty">
              {{ $t('mails.messages.validation.subject_required') }}
            </span>
              <span class="error pt-1" v-if="!vuelidate.mailTemplate.subject.maxLength && vuelidate.$dirty">
              {{ $t('mails.messages.validation.subject_maxlength') }}
            </span>
            </template>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <div class="d-flex align-items-center m__bottom--6">
                    <span class="input-title fs-12 fw__bold color__blue_main">{{$t('mails.input_field.content.label')}}<i
                        :data-tooltip="$t('tooltip.required')"
                        class="required-note">*</i></span>
          </div>
          <div class="">
            <label>
              <editor :class="{'is-error': !vuelidate.mailTemplate.body.required && vuelidate.$dirty}"
                      class="body"
                      id="body"
                      name="body"
                      v-model.trim="mailTemplate.body"
                      :other_options="customToolbar"
                      :toolbar1="toolbar1"
                      :plugins="plugins"
              >
              </editor>

            </label>
            <span class="error pt-1" v-if="error_server.body && error_server.body.length">
            {{ error_server.body[0] }}
          </span>
            <template v-else-if="vuelidate.$dirty">
                      <span class="error pt-1" v-if="!vuelidate.mailTemplate.body.required && vuelidate.$dirty">
                        {{ $t('mails.messages.validation.body_required') }}
                      </span>
            </template>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-full">
          <div class="field is-grouped is-grouped-centered">
            <div class="control">
              <button @click="submit()" class="button btn-save background__blue_main">
                {{ isUpdate ? $t('mails.buttons.update') : $t('mails.buttons.save') }}
              </button>
            </div>
            <div class="control">
              <button @click="backToList()" class="button btn-back background__white_pink color__btn_back">
                {{$t('mails.buttons.cancel')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions";
  import {maxLength, required} from "vuelidate/lib/validators";
  import {MailTemplateService} from "@/services";
  // import {XSSCheck} from "../../helpers/utils";
  // import Editor from '@tinymce/tinymce-vue';
  import tinymce from 'vue-tinymce-editor'
  import i18n from "../../lang/i18n";

  export default {
    name: "MailTemplateCreate",
    props: {
      mailTemplateUpdate: {
        type: Object,
        default: () => {
        },
      },
      title: {
        type: String,
        default: '',
      }
    },
    data(vm) {
      return {
        mailTemplate: {
          name: '',
          subject: '',
          body: '',
          code: '',
        },
        isUpdate: false,
        isValidBody: false,
        customToolbar: {
          setup: function (editor) {
            let placeholder = '<p style="color: #ccc;" id="editor-placeholder">' + i18n.t('mails.input_field.content.placeholder') + '</p>'
            let mceContent = document.getElementsByClassName('mce-tinymce')
            let content = '<p>&nbsp;</p>'
            editor.on('init', function () {
              editor.setContent(placeholder);
            });
            editor.on('click', function () {
              mceContent[0].classList.add('hover-editor')
              let index = editor.getContent().includes(placeholder)
              if(index) {
                localStorage.setItem('isValidBody','true')
              }else {
                localStorage.setItem('isValidBody','false')
              }
            });
            editor.on('focus', () => {
              if (vm.stringToHTML(editor.getContent()) === i18n.t('mails.input_field.content.placeholder')) {
                editor.setContent('')
              }
            });
            editor.on('keyup', function () {
              if(editor.getContent() === '') {
                localStorage.setItem('isValidBody','false')
              }else {
                localStorage.setItem('isValidBody','true')
              }
            });
            editor.on('blur', function () {
              if( !content || vm.stringToHTML(content) === '&nbsp;') {
                editor.setContent(placeholder)
                localStorage.setItem('isValidBody', 'false')
              }else {
                localStorage.setItem('isValidBody', 'true')
              }
            });
            editor.on('mouseenter', function () {
              mceContent[0].classList.add('hover-editor')
            });
            editor.on('change', function () {
              placeholder = document.getElementById('editor-placeholder')
              content = editor.getContent()
            });
          },
          branding: false,
          height: 200,
          menubar: false,
        },
        toolbar1: 'formatselect | bold italic  strikethrough  forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | code',
        plugins: [
          'visualblocks advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        error_server: {},
        submitAvailable: true
      }
    },
    components: {
      'editor': tinymce
    },
    validations: {
      mailTemplate: {
        name: {
          required,
          maxLength: maxLength(50),
          // XSSCheck: (val) => {
          //   return XSSCheck(val)
          // }
        },
        subject: {
          required,
          maxLength: maxLength(50)
        },
        body: {
          required: function (val) {
            if (!val) {
              return false;
            }
            return localStorage.getItem('isValidBody') ? localStorage.getItem('isValidBody') == 'true' : true
          }
          // maxLength: maxLength(1000)
        }
      }
    },
    watch: {
      mailTemplateUpdate(val) {
        if (val) {
          this.mailTemplate = {...val}
          this.isUpdate = true
        } else {
          this.isUpdate = false
        }
      }
    },
    methods: {
      stringToHTML(str) {
        let parser = new DOMParser()
        let doc = parser.parseFromString(str, 'text/html')
        return doc.body.firstChild ? doc.body.firstChild.innerHTML : ''
      },
      submit() {
        this.error_server = {}
        this.vuelidate.$touch();
        if (!this.vuelidate.$invalid && this.submitAvailable) {
          this.submitAvailable = false
          if (this.isUpdate) {
            MailTemplateService.update(this.mailTemplate.id, this.mailTemplate)
              .then(() => {
                this.submitAvailable = true
                this.$toast.success(this.$t('mails.messages.update_success'))
                this.$router.push({name: 'MailTemplate'}, () => {
                })
              })
              .catch((err) => {
                this.submitAvailable = true
                this.error_server = err.data.errors
                // this.$toast.error(err.data.message)
              })
          } else {
            this.mailTemplate.code = Math.random().toString(36).substring(7)
            MailTemplateService.create(this.mailTemplate)
              .then(() => {
                this.submitAvailable = true
                this.$toast.success(this.$t('mails.messages.create_success'))
                this.$router.push({name: 'MailTemplate'}, () => {
                })
              })
              .catch((err) => {
                this.submitAvailable = true
                this.error_server = err.data.errors
                // this.$toast.error(err.data.message)
              })
          }
        }
      },
      backToList() {
        this.$router.push({name: 'MailTemplate'}, () => {
        })
      }
    },
    created() {
      localStorage.removeItem('isValidBody')
      this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'mail-templates')
    }
  }
</script>

<style lang="scss" scoped>
  .ck.ck-editor__main {
    .ck-content {
      height: 200px !important;
    }
  }
  ::v-deep {
    .mce-container  {
      box-shadow: none !important;
      border-color: #dbdbdb !important;
    }
  }
  .mail-create-create {
    padding: 10px 60px;
    border-radius: 10px 10px 0 0;
  }
  .page-main-content {
    padding: 20px 60px;
    border-radius: 0 0 10px 10px;
    .columns {
      margin: 0px;
      .column {
        padding-left: 0px;
        padding-bottom: 0px;
      }
    }
  }
  button {
    &.btn-save {
      border-radius: 18px !important;
      border: none !important;
      padding: 0px !important;
      height: 35px !important;
      width: 140px !important;
      font-size: 16px !important;
      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
    &.btn-back {
      border-radius: 18px !important;
      border: none !important;
      padding: 0px !important;
      height: 35px !important;
      width: 140px !important;
      font-size: 16px !important;
      color: $btn_back !important;
    }
  }
  .input {
    color: $blue_main !important;
    font-size: 12px !important;
    height: 40px !important;
    &:hover,&:focus {
      border-color: $blue_main !important;
      box-shadow: none !important;
    }
  }
</style>

<style lang="scss">
  .mce-path, .mce-wordcount {
    display: none !important;
  }

  .mce-statusbar {
    border-width: 0 !important;
  }

  .hover-editor {
    &:hover {
      box-shadow: 0 0 5px #61C5FA;
    }
  }

  .is-error .mce-tinymce {
    border: 1px solid #ff6673;
  }
</style>